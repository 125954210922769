import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import { Subject, catchError, of, takeUntil, tap } from 'rxjs';
import { ProviderApiModel, ProviderService } from 'src/apis/backend';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';

@Component({
  selector: 'app-home',
  styleUrls: ['./home.component.scss'],
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit, OnDestroy, AfterViewInit {
  private destroy$ = new Subject<void>();

  providerInfo?: ProviderApiModel | null;

  constructor(
    private readonly providerService: ProviderService,
    private readonly analyticsService: AnalyticsService
  ) {}

  ngOnInit(): void {
    const unauthorizedUrl = `${(window as any).SGSCustom.sgsEnvProperties.unauthorizedSgMarketsUrl}`;
    this.providerService.apiV1ProvidersProviderNameGet('greenscope').pipe(
      tap(result => this.providerInfo = result),
      catchError(() => of(null)),
      takeUntil(this.destroy$)
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  ngAfterViewInit(): void {
    this.analyticsService.trackEventNavigateHomePage();
  }
}
