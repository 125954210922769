import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SGWTConnectCore } from '@sgwt/connect-angular';
import { ISgwtConnectElement } from 'src/main';
import { ProviderApiModel } from 'src/apis/backend';
import { BehaviorSubject, Subject, combineLatest, takeUntil, tap } from 'rxjs';
import { BearerTokenService } from '../../services/bearer-token.service';

// eslint-disable-next-line
declare const GreenscopeIframeClient: any;

@Component({
  selector: 'app-vendor-service',
  templateUrl: './vendor-service.component.html',
  styleUrls: ['./vendor-service.component.scss'],
})
export class VendorServiceComponent implements AfterViewInit, OnInit, OnDestroy {
  private _providerInfo?: ProviderApiModel | null;

  @Input() get providerInfo(): ProviderApiModel | null | undefined {
    return this._providerInfo;
  }

  set providerInfo(value: ProviderApiModel | null | undefined) {
    this._providerInfo = value;
    this.providerInfo$.next(value);
  }

  public loaded = false;

  @ViewChild('clarityIframe', { static: false })
  private readonly clarityIframeRef?: ElementRef;

  private sgwtConnect?: SGWTConnectCore;
  private gsIframeClient?: any;

  private afterViewInit$ = new Subject<void>();
  private providerInfo$ = new BehaviorSubject<ProviderApiModel | null | undefined>(null);
  private destroy$ = new Subject<void>();

  constructor(private bearerTokenService: BearerTokenService) {}

  ngOnInit(): void {
    combineLatest([this.afterViewInit$, this.providerInfo$])
    .pipe(
      tap(() => this.initialize()),
      takeUntil(this.destroy$)
    ).subscribe();
  }

  ngAfterViewInit(): void {
    this.afterViewInit$.next();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  onClarityIframeLoaded() {
    if (this.clarityIframeRef) {
      this.loaded = true;
      // this.iframeRef?.nativeElement.contentWindow.postMessage(this.sgwtConnect?.getIdToken(), '*');
    }
  }

  private initialize() {
    const wrapper = document.querySelector('sgwt-connect') as ISgwtConnectElement;
    this.sgwtConnect = wrapper.sgwtConnect;

    if (this.sgwtConnect && this.providerInfo?.name === 'greenscope') {
      this.gsIframeClient = new GreenscopeIframeClient({
        parentElementId: 'greenscope-iframe-holder',
        elementId: 'greenscope-iframe',
        authProviderId: '752c3b16-4106-4919-9f72-6d6762cf0504',
        className: 'iframe',
        style: { border: 'none' },
      });

      this.performGreenscopeLogin();

      this.sgwtConnect.on('renewAuthorizationSuccess', () => {
        // this.iframeRef?.nativeElement.contentWindow.postMessage(this.sgwtConnect?.getIdToken(), '*');
        this.performGreenscopeLogin();
      });
    }
  }

  private performGreenscopeLogin() {
    const token = this.bearerTokenService.getToken();
    this.gsIframeClient.login(token, '/');
  }
}
